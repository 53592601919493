import axios from 'axios';
import { CollectionResponse, EnumEntity, Invoice } from '../model';
import { format } from 'date-fns';

export interface GetInvoicesParams extends GetInvoicesFilter {
  terminalId?: string;
  page?: number;
  search?: string;
  size?: number;
}

export type GetInvoicesFilter = Partial<{
  dateFrom: Date | null;
  dateTo: Date | null;
  flowType: string | null;
  lastStatusDate: Date | null;
  statusIds: string[];
  sumFrom: number | null;
  sumTo: number | null;
}>;

export interface ChangeStatusParams {
  invoiceId: string;
  statusId: number;
}

export interface ChangeAmountParams {
  invoiceId: string;
  amount: number;
}

type Error = {
  description: string,
  eventTime: Date
}

export class InvoiceApi {
  static readonly KEY = 'invoice';

  static async changeStatus(params: ChangeStatusParams): Promise<CollectionResponse<Invoice>> {
    const { data } = await axios.patch('/api/v1/invoice/status', params);
    return data;
  }

  static async changeAmount(params: ChangeAmountParams): Promise<CollectionResponse<Invoice>> {
    const { data } = await axios.patch('/api/v1/invoice/amount', params);
    return data;
  }

  static async getByMerchant(params: GetInvoicesParams): Promise<CollectionResponse<Invoice>> {
    const { data } = await axios.get('/api/v1/invoice', {
      params: {
        ...Object.fromEntries(Object.entries(params).filter(([_, value]) => value !== '')),
        dateFrom: params.dateFrom ? format(params.dateFrom, 'dd/MM/yyyy') : undefined,
        dateTo: params.dateTo ? format(params.dateTo, 'dd/MM/yyyy') : undefined,
        lastStatusDate: params.lastStatusDate ? format(params.lastStatusDate, 'dd/MM/yyyy') : undefined,
        sumFrom: params.sumFrom?.toString(),
        sumTo: params.sumTo?.toString(),
      },
      paramsSerializer: {
        indexes: null,
      },
    });

    return data;
  }

  static async getById(id: string): Promise<Invoice> {
    const { data } = await axios.get(`/api/v1/invoice/${id}`);

    return data.data;
  }

  static async getEvents(invoiceId: string): Promise<
    Array<{
      status: EnumEntity;
      statusDate: string;
    }>
  > {
    const { data } = await axios.get('/api/v1/event/status', {
      params: {
        invoiceId,
      },
    });

    return data.data;
  }

  static async getErrors(id: string): Promise<Error[]> {
    const { data } = await axios.get(`/api/v1/event/error?invoiceId=${id}`);

    return data.data;
  }

  static async getReport(params: Omit<GetInvoicesParams, 'page' | 'size'>): Promise<void> {
    const queryParams = {
      ...params,
      dateFrom: params.dateFrom ? format(params.dateFrom, 'dd/MM/yyyy') : undefined,
      dateTo: params.dateTo ? format(params.dateTo, 'dd/MM/yyyy') : undefined,
      lastStatusDate: params.lastStatusDate ? format(params.lastStatusDate, 'dd/MM/yyyy') : undefined,
      sumFrom: params.sumFrom?.toString(),
      sumTo: params.sumTo?.toString(),
    }

    delete queryParams['terminalId']

    if(!!params.terminalId) {
      queryParams.terminalId = params.terminalId
    }

    const { data } = await axios.get('/api/v1/invoice/csv', {
      params: queryParams,
      paramsSerializer: {
        indexes: null,
      },
    });

    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Invoices.csv');
    document.body.appendChild(link);
    link.click();
  }
}

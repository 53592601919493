import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type { ChangeEvent, FC } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useCopyToClipboard } from 'usehooks-ts';
import { InvoiceApi, SupportApi } from '../api';
import { BackButton, Button, InvoiceStatus, InvoiceStatusSelector, Modal, Money, Spinner, Tag } from '../components';
import { TagType } from '../model';
import { useProfile } from '../hooks';
import { ChevronUpIcon } from '@heroicons/react/16/solid';
import { Disclosure } from '@headlessui/react';
import cx from 'classnames';
import { normalizeAmount } from '../utils';
import { formatDateUTC } from "../utils/lib";

export const InvoiceDetailsPage: FC = () => {
  const profile = useProfile();
  const { id } = useParams();

  const queryClient = useQueryClient()

  const [, copy] = useCopyToClipboard();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);

  const { data: invoice } = useQuery({
    queryKey: [InvoiceApi.KEY, 'getByMerchant', id],
    queryFn: () => InvoiceApi.getById(id!),
    enabled: Boolean(id),
  });

  const [updatedAmount, setUpdatedAmount] = useState<string | null>(null);

  const { data: events } = useQuery({
    queryKey: [InvoiceApi.KEY, 'getEvents', id],
    queryFn: () => InvoiceApi.getEvents(id!),
    enabled: Boolean(id),
  });

  const { data: supportIssues = [] } = useQuery({
    queryKey: [SupportApi.KEY, 'getIssuesByInvoice', invoice?.invoiceId],
    queryFn: () => SupportApi.getIssuesByInvoice(invoice!.invoiceId),
    enabled: Boolean(invoice),
  });

  const isErrorsVisible = profile?.permissions.includes('invoice.error.read');
  const canChangeAmount = profile?.permissions.includes('invoice.amount.change');

  const { data: errors } = useQuery({
    queryKey: [InvoiceApi.KEY, 'event', id],
    queryFn: () => InvoiceApi.getErrors(id!),
    enabled: isErrorsVisible && !!id,
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: InvoiceApi.changeAmount,
    onSuccess() {
      setIsOpen(false);
      setIsOpenConfirm(false);
      queryClient.refetchQueries([InvoiceApi.KEY, 'getByMerchant', id]);
    },
    onError() {
      toast.error('Failed to change status');
    },
  });

  const onConfirm = () => {
    updatedAmount && id && mutate({
      invoiceId: id,
      amount: updatedAmount.split('.')[1] ? +String(updatedAmount).split('.').join('') : +`${updatedAmount}${'0'.repeat(invoice!.currency.dimension)}`,
    });
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value.replace(/[^0-9.]/g, '');

    const dotCount = (value.match(/\./g) || []).length;
    if (dotCount > 1) {
      value = value.slice(0, value.indexOf('.') + 1) + value.slice(value.indexOf('.') + 1).replace(/\./g, '');
    }

    event.target.value = value;
    setUpdatedAmount(value);
  };


  const onChangeAmountSubmit = () => {
    if (updatedAmount) {
      let parts = updatedAmount.split('.');

      if (parts[1] && parts[1].length > 2) {
        toast.error('There must be max two decimals after comma')
        setUpdatedAmount(null);
        return
      }

      setIsOpenConfirm(true);
      return;
    }
    toast.error('Change the value');
  };

  if (!invoice) {
    return <Spinner />;
  }

  const canChangeInvoiceStatus = profile?.permissions.includes('invoice.status.change');

  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="p-7 w-3/5 bg-white rounded-3xl row-g">
          <div className="flex justify-between items-center pb-8">
            <div className="flex justify-between items-center gap-5">
              <BackButton />
              <span className="flex items-center gap-2 text-lg">
                Transaction
                {invoice.isTestProcessing && (
                  <Tag className="opacity-50" type={TagType.Neutral}>
                    Test
                  </Tag>
                )}
                <sup className="capitalize text-xs text-brand-2">
                  {formatDateUTC(invoice.statusDate)}
                </sup>
              </span>
            </div>
            <Tag
              className="px-2 py-1 cursor-pointer"
              onClick={() => {
                copy(String(invoice.invoiceId));
                toast.success('Invoice ID copied to clipboard');
              }}
              type={TagType.Primary}
            >
              Copy
            </Tag>
          </div>
          <div className="flex justify-between items-center">
            <div className="uppercase font-semibold text-sm">Amount</div>
            <div className="font-semibold text-base text-green-500 flex flex-col">
              <Money amount={invoice.amount} currency={invoice.currency} />
            </div>
          </div>
          <div className="flex justify-between items-center mt-3">
            <div className="uppercase font-semibold text-sm">Fee</div>
            <div className="font-semibold text-base text-green-500">
              <Money amount={invoice.fee ? invoice.fee : 0} currency={invoice.currency} />
            </div>
          </div>
          <div className="flex justify-between items-center mt-3">
            {canChangeAmount && <Tag
              className="px-2 py-1 cursor-pointer"
              onClick={() => {
                setIsOpen(true);
              }}
              type={TagType.Primary}
            >
              Change amount
            </Tag>}
          </div>
          <hr className="my-6 border-slate-100" />
          <div className="text-xs text-[#040304]">
            <div className="grid grid-cols-[1fr_2fr] gap-4 mb-5">
              <span className="w-40 text-brand-2">Flow Type</span>{' '}
              <span className="capitalize">{invoice.flowType.code.toLowerCase()}</span>
            </div>
            <div className="grid grid-cols-[1fr_2fr] gap-4 mb-5">
              <span className="w-40 text-brand-2">Invoice ID</span> <span>{invoice.invoiceId}</span>
            </div>
            <div className="grid grid-cols-[1fr_2fr] gap-4 mb-5">
              <span className="w-40 text-brand-2">Merchant Invoice ID</span>
              <span>{invoice.merchantInvoiceId}</span>
            </div>
            <div className="grid grid-cols-[1fr_2fr] gap-4 mb-5">
              <span className="w-40 text-brand-2">User ID</span>
              <span className="break-all">
            {invoice.customer.externalId}
          </span>
            </div>
            <div className="grid grid-cols-[1fr_2fr] gap-4 mb-5">
              <span className="w-40 text-brand-2">Owner name</span>
              <span>
            {invoice.customer.people.firstName}&nbsp;{invoice.customer.people.lastName}
          </span>
            </div>
            <div className="grid grid-cols-[1fr_2fr] gap-4 mb-5">
              <span className="w-40 text-brand-2">Terminal ID</span>
              <span>               {invoice.terminal?.id}            </span>
            </div>
            <div className="grid grid-cols-[1fr_2fr] gap-4 mb-5">
              <span className="w-40 text-brand-2">Terminal name</span>
              <span>               {invoice.terminal?.name}            </span>
            </div>
            <div className="grid grid-cols-[1fr_2fr] gap-4 mb-5">
              <span className="w-40 text-brand-2">Status</span>
              <span className="w-fit">
              {(invoice.isTestProcessing || canChangeInvoiceStatus)
                ? <InvoiceStatusSelector invoiceId={invoice.invoiceId} statusId={invoice.invoiceStatus.id}
                                         status={invoice.invoiceStatus} />
                : <InvoiceStatus status={invoice.invoiceStatus} />
              }
            </span>
            </div>
            <div className="grid grid-cols-[1fr_2fr] gap-4 mb-5">
              <span className="w-40 text-brand-2">Status date</span>{' '}
              <span>
                {formatDateUTC(invoice.statusDate, 'long')}
              </span>
            </div>
            <div className="grid grid-cols-[1fr_2fr] gap-4 mb-5">
              <span className="w-40 text-brand-2">Currency</span>
              <span>{invoice.currency.code}</span>
            </div>
            <div className="grid grid-cols-[1fr_2fr] gap-4 mb-5">
              <span className="w-40 text-brand-2">Payment method</span>
              <span>{invoice.paymentMethod?.paymentMethod?.name ?? '-'}</span>
            </div>
            {profile?.permissions.includes('invoice.credentials.read') && (
              <div className="grid grid-cols-[1fr_2fr] gap-4 mb-5">
                <span className="w-40 text-brand-2">Credentials</span>
                <span>{invoice.account?.value}</span>
              </div>
            )}
            {invoice.credentials && (
              <>
                <div className="grid grid-cols-[1fr_2fr] gap-4 mb-5">
                  <span className="w-40 text-brand-2">Bank name</span>
                  <span>{invoice.credentials?.bankName}</span>
                </div>
                <div className="grid grid-cols-[1fr_2fr] gap-4 mb-5">
                  <span className="w-40 text-brand-2">Owner</span>
                  <span>{invoice.credentials?.owner}</span>
                </div>
              </>
            )}
          </div>
          {events && events.length > 0 && (
            <>
              <hr className="my-6 border-slate-100" />
              <h2 className="mb-5 text-sm uppercase text-brand-6 font-semibold">History of status changes</h2>
              <ul className="grid gap-4">
                {events?.map((event) => (
                  <li className="flex items-center gap-7" key={event.statusDate}>
                    <span className="text-brand-2 text-xs">
                      {formatDateUTC(event.statusDate, 'long')}
                    </span>
                    <InvoiceStatus status={event.status} />
                  </li>
                ))}
              </ul>
            </>
          )}
          {supportIssues.length > 0 && (
            <div className="pt-6">
              <div className="font-bold text-2xl mb-6">Support History</div>
              {supportIssues.map((issue) => (
                <div className="mb-6 flex justify-between items-center" key={issue.id}>
                  <div className="flex items-center gap-3">
                    <div className="flex flex-col justify-between gap-1">
                      <div className="uppercase font-bold text-sm">{issue.id} </div>
                      <div className="capitalize text-xs text-brand-2">{issue.description} </div>
                    </div>
                  </div>
                  <Tag type={TagType.Warning}>{issue.status.value}</Tag>
                </div>
              ))}
            </div>
          )}
        </div>
        {isErrorsVisible && (
          <Disclosure>
            {({ open }) => (
              <div className={cx(
                'p-7 w-3/5 bg-white rounded-3xl',
              )}>
                <Disclosure.Button
                  as={'h2'}
                  className={
                    cx(
                      'flex justify-between text-sm uppercase text-brand-6 font-semibold cursor-pointer',
                      errors?.length ? 'text-rose-500' : 'text-green-500',
                      open && 'mb-5',
                    )}
                >
                  <span>Errors ({errors?.length})</span>
                  <ChevronUpIcon
                    className={cx(
                      open ? 'rotate-180 transform' : '',
                      errors?.length ? 'text-rose-500' : 'text-green-500',
                      'h-5 w-5',
                    )}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="grid gap-4">
                  {errors && errors.length
                    ? errors.map(error => {
                      return (
                        <div className="grid gap-4 text-xs">
                          <hr className="border-slate-100" />
                          <span className="w-full text-brand-2 m-0">{error.description}</span>
                          <span className="w-full self-end m-0">
                            {formatDateUTC(error.eventTime, 'long')}
                          </span>
                        </div>
                      );
                    })
                    : (
                      <div className="grid gap-4 text-xs">
                        <hr className="border-slate-100" />
                        <span className="w-full text-brand-2 m-0">No errors</span>
                      </div>
                    )}
                </Disclosure.Panel>
              </div>
            )}
          </Disclosure>
        )}
      </div>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <div className="p-10">
          <input
            className="mt-1 max-h-80 input rounded-md p-2 w-full"
            defaultValue={normalizeAmount(invoice.amount, invoice.currency)}
            onChange={onChange} />
          <div className="mt-8 flex justify-center gap-4">
            <Button onClick={() => setIsOpen(false)}>Cancel</Button>
            <Button onClick={onChangeAmountSubmit}>Continue</Button>
          </div>
        </div>
      </Modal>
      <Modal isOpen={isOpenConfirm} onClose={() => setIsOpenConfirm(false)}>
        <div className="p-6">
          <h2 className="text-lg text-center">
            Are you sure you want to change the amount to{' '}
            <strong>{updatedAmount && updatedAmount.split('.')[1] ? updatedAmount : `${updatedAmount}.00`}</strong>?
          </h2>
          <div className="mt-8 flex justify-center gap-4">
            <Button onClick={() => {
              setIsOpenConfirm(false);
            }}>Cancel</Button>
            <Button onClick={onConfirm} disabled={isLoading}>Continue</Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
